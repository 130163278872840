
import { Delete, Edit, View, Search,Coin,User } from "@element-plus/icons-vue";
import { defineComponent } from "vue";
import { ElMessage, ElMessageBox } from "element-plus";
import { client } from "@/client";
import { UserData} from "@/shared/types/UserData";
import diamond from "./diamond.vue";
import fish from "./fish.vue";
import { OrderBy} from "@/shared/types/BaseType";
import { ReqList } from "@/shared/adminprotocols/user/PtlList";
import { isUrl } from "@/utils/tools";
export default defineComponent({
  name: "user-index",
  components:{
    diamond,
    fish
  },
  setup() {
    return {
      Edit,
      Delete,
      View,
      Search,
      Coin,
      User
    };
  },
  data: () => {
    return {
      keywords: "",
      cur: 1,
      tableData: [] as UserData[],
      multipleSelection: [] as any[],
      orderBy:{
          sort:"loginTime" as keyof UserData,
          order:"descending" as OrderBy
      },
      userinfo:{
          diamond:0,
          nickName:'',
          acount:"",
          id:0
      },
      userid:0,
      dialogFormVisible:false,
      fishVisible:false,
      formLabelWidth:'100px',
      count: 0,
      loading: true,
      pageSize:15,
    };
  },
  computed: {},
  created() {
    let cur = this.$route.query.cur as string;
    this.cur = parseInt(cur);
    if (isNaN(this.cur)) {
      this.cur = 1;
    }
    let keywords = this.$route.query.keywords as string;
    this.keywords = keywords as string;
    this.search();
  },

  watch: {
    $route(to, from) {
      console.log(to);
      if (to.name == "user-index") {
        let { cur, keywords } = to.query;
        this.cur = +cur;
        this.keywords = keywords;
        this.search();
      }
    },
  },
  methods: {
    //清空搜索
    clearSearch() {
      this.$router.replace({
        name: "user-index",
        query: { cur: 1, cid: 0, keywords: "" },
      });
    },
    doSearch() {
      this.$router.replace({
        name: "user-index",
        query: { cur:1, keywords: this.keywords },
      });
      this.search();
    },
      handleSizeChange(val: { pageSize: number; }){
      this.pageSize = val.pageSize;
      this.search();
      },
      headIcon(avater:string){
          let path = avater;
          if (path) {
            let isurl = isUrl(path);
            if (!isurl) {
                path = client.options.server + path;
            }
          console.error(isurl)
          }
        return path

      },
    //查询
    async search() {
      let orderData:any = {sort:this.orderBy.sort}
      if (this.orderBy.order) {
          orderData.order = this.orderBy.order =="ascending"?"ASC" : "DESC";
      }
      this.dialogFormVisible = false;
      this.fishVisible = false;
      let data:ReqList = {
        cur:this.cur,
        nickName:this.keywords,
        pageSize:this.pageSize,
        orderBy:orderData
      }
      let res = await client.callApi("user/List",data);
      if (res.isSucc) {
        let list = res.res.list;
        this.tableData = [...list];
        this.count = res.res.count;
        this.loading = false;
      }
    },

    //翻页
    handleCurrentChange(e: number) {
      this.loading = true;
      this.cur = +e;
      this.doSearch();
    },

    toggleSelection(rows: any[]) {
      let refs:any = this.$refs.multipleTable;
      if (rows) {
        rows.forEach((row) => {
          refs.toggleRowSelection(row);
        });
      } else {
        refs.clearSelection();
      }
    },

    handleSelectionChange(val: any[]) {
      this.multipleSelection = val;
    },    
    //跳转到编辑
    toEdit(e: { id: any; }) {
      let id = e.id;
      console.error(id,"跳转到编辑")
      this.$router.push({ name: "user-edit", params: { id: id } });
    },

    delSome() {
      let ids = this.multipleSelection.map((item) => {
        return parseInt(item.id);
      });
      ElMessageBox.confirm("此操作将永久删除, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
        let res = await client.callApi("user/Del",{ids:ids});
        if (res.isSucc) {
            ElMessage({
              message: "删除成功 ^_^",
              type: "success",
            });
            this.search();
          }
        })
        .catch(() => {
          ElMessage({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    hide(){
      this.dialogFormVisible = false;
    },
    hideFish(){
      this.fishVisible = false;
    },
    //删除文章
    async handleDel(e: { id: any; }) {
      let id = e.id;
      try {
        let res = await client.callApi("user/Del",{ids:[id]});
        if (res.isSucc) {
          ElMessage({
            message: "文章删除成功 ^_^",
            type: "success",
          });
          this.search();
        }
      } catch (error) {
        console.log(error);
      }
    },
    handleFriendClick(e:any) {
      let id = e.id;
      this.$router.push({ name: "friend-index", params: { id: id } });
    },
    handleFishClick(e:any) {
      console.error(e);
      this.fishVisible = true;
      this.userid =e.id;
    },
    handleClick(e:any) {
      console.error(e);
      this.dialogFormVisible = true;
      this.userinfo.id =e.id;
      this.userinfo.diamond =e.diamond;
      this.userinfo.acount =e.account.account;
      this.userinfo.nickName = e.nickName;
    },
      formatter(row: { avater: string; }, column: any,cellvalue:string) {
        if (row.avater==""|| !row.avater) {
          return "无";
        } else {
            return row.avater;
        }
      },
      formatterMobile(row: { mobile: string; }, column: any) {
          return row.mobile==""|| !row.mobile?"无":row.mobile;
      },
      handleChangeSort(e:{prop:keyof UserData,order:'descending'|"ascending"|null}){
          console.error(e);
          this.orderBy = {
              sort:e.prop,
              order:e.order
          }

          this.search();
      }
  },
  onBeforeUnmount(e:any) {
    console.error(e);

  },
});
