import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "dialog-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    modelValue: _ctx.dialogFormVisible,
    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.dialogFormVisible) = $event)),
    onClose: _cache[7] || (_cache[7] = ($event: any) => (_ctx.dialogFormVisible = false)),
    onOpen: _cache[8] || (_cache[8] = ($event: any) => (_ctx.detail())),
    title: "钻石充值"
  }, {
    footer: _withCtx(() => [
      _createElementVNode("span", _hoisted_1, [
        _createVNode(_component_el_button, {
          onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.hide())),
          round: ""
        }, {
          default: _withCtx(() => [
            _createTextVNode("取消")
          ]),
          _: 1
        }),
        _createVNode(_component_el_button, {
          type: "primary",
          onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.confirm())),
          round: ""
        }, {
          default: _withCtx(() => [
            _createTextVNode(" 确定 ")
          ]),
          _: 1
        })
      ])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_el_form, {
        model: _ctx.dataForm,
        "label-width": "120px"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_form_item, {
            label: "用户ID",
            "label-width": _ctx.formLabelWidth
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                modelValue: _ctx.dataForm.id,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.dataForm.id) = $event)),
                autocomplete: "off",
                readonly: "",
                disabled: true
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }, 8, ["label-width"]),
          _createVNode(_component_el_form_item, {
            label: "用户账号",
            "label-width": _ctx.formLabelWidth
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                modelValue: _ctx.dataForm.account,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.dataForm.account) = $event)),
                autocomplete: "off",
                readonly: "",
                disabled: true
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }, 8, ["label-width"]),
          _createVNode(_component_el_form_item, {
            label: "用户昵称",
            "label-width": _ctx.formLabelWidth
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                modelValue: _ctx.dataForm.nickName,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.dataForm.nickName) = $event)),
                autocomplete: "off",
                readonly: "",
                disabled: true
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }, 8, ["label-width"]),
          _createVNode(_component_el_form_item, {
            label: "钻石余额",
            "label-width": _ctx.formLabelWidth
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                modelValue: _ctx.dataForm.diamond,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.dataForm.diamond) = $event)),
                autocomplete: "off",
                readonly: "",
                disabled: true
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }, 8, ["label-width"])
        ]),
        _: 1
      }, 8, ["model"])
    ]),
    _: 1
  }, 8, ["modelValue"]))
}