
import { client } from "@/client";
import { ElMessage } from "element-plus";
import { defineComponent,ref } from "vue";
export default defineComponent({
    props: {
        visible: { type: Boolean, default: false },
        userinfo:{type:Object}
    },
    data(){
        return {
            amount:"0",
            dataForm:this.userinfo as {
                diamond:0,
                nickName:'',
                acount:"",
                id:0
            },
            dialogFormVisible:false,
            formLabelWidth:'120px'
        }
    },
    methods:{
        async confirm(){
            this.dialogFormVisible = false;
            let ret = await client.callApi("user/Recharge",{
                id:this.dataForm.id,
                amount:parseInt(this.amount) 
            });
            ElMessage({
                message:ret.isSucc?"更新成功^_^":ret.err.message,
                type: ret.isSucc?"success":"error",
            });
            let page:any = this.$parent;
            page.search();
        },
        hide(){
            console.error(this.dialogFormVisible);
            this.dialogFormVisible = false;
            console.error(this.dialogFormVisible);
            let page:any = this.$parent;
            page.hide();
        }
    }
})
