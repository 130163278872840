
import { client } from "@/client";
import { defineComponent,ref } from "vue";
const formData = {
    diamond:0,
    nickName:'',
    account:'',
    id:0,
    historyReward:0,
    nReward:0,
    nRewardLibrary:0,
    nAccumateLibrary:0,
    nTodayShotCount:0,
    nTotalShotCount:0,
    nGameWinScore:0,
    nGameCostScore:0,
    nTodayProfitScore:0,
    nPeriodInningOfGame:0,
    nPeriodProfitScore:0,
    nCZContributeScore:0,
}
export default defineComponent({
    props: {
        visible: { type: Boolean, default: false },
        userid:{type:Number,default:0},
        userinfo:{type:Object}
    },
    data(){
        return {
            amount:"0",
            dataForm:formData as {
                diamond:number,
                nickName:string,
                account:string,
                id:number,
            },
            dialogFormVisible:false,
            formLabelWidth:'150px'
        }
    },
    async create(){
      await this.detail();
    },
    methods:{
      // 文章详情
      async detail() {
        try {
          let res = await client.callApi("user/Detail",{id:this.userid});
          if (res.isSucc) {
            let data = res.res;
            this.dataForm = {
                ...data,
                diamond:data.diamond,
                account:(data.account?data.account.account:""),
            }
          }
        } catch (error) {
          console.error(error);
        }
      },
      async confirm(){
          this.dialogFormVisible = false;
          let page:any = this.$parent;
          page.search();
      },
      hide(){
          this.dialogFormVisible = false;
          let page:any = this.$parent;
          page.hideFish();
      }
    }
})
